import { useState } from "react";
import { useApiProduto } from "../apirest/useApiProduto";
import usePaginacaoLista from "./usePaginacaoLista";
import { retiraAcentosMinusculo } from "../Util/RetiraAcentos";
import { useQuery } from "react-query";
import { categoriasProduto } from "../constants/CategoriasProduto";
import { useNavigate, useSearchParams } from "react-router-dom";
import { querysTelaProdutos } from "../Util/NomesQuerysProdutos";
import {
  camposOrdenacaoProduto,
  valorOrdenacaoProduto,
} from "../constants/CamposOrdenacaoProduto";

export const useProdutos = ({ usaCategoriaComoFiltro = true }) => {
  const [carregando, setCarregando] = useState(false);
  const api = useApiProduto();
  const [nome, setNome] = useState("");
  const nomeQuerysProduto = querysTelaProdutos;
  const palavrasIniciais = () => {
    return categoriasProduto.map((categoria, index) => {
      return {
        palavra: categoria.label.toUpperCase(),
        valor: categoria.value,
        marcada: false,
        tamanho: categoria.tamanho,
        url: `/produtos/`,
        search: `?categoria=${categoria.value}`,
      };
    });
  };

  const carregarProdutos = async () => {
    setCarregando(true);
    try {
      const { data } = await api.listar();
      if (data && data.doc) {
        const listaProdutos = data.doc.sort(() => Math.random() - 0.5);
        return listaProdutos;
      }
      return [];
    } catch (error) {
      console.error("Erro ao carregar produtos:", error);
    } finally {
      setCarregando(false);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const categoria = searchParams.get("categoria");

  const { data: produtos } = useQuery({
    queryFn: async () => await carregarProdutos(),
    queryKey: [nomeQuerysProduto.PRODUTOS],
  });

  const ordenarProdutos = (listaProdutos) => {
    if (ordenacao) {
      switch (ordenacao) {
        case valorOrdenacaoProduto.DESIGNER_CRESCENTE:
          listaProdutos = listaProdutos.sort((prod1, prod2) =>
            prod1.designer.name.localeCompare(prod2.designer.name),
          );
          break;
        case valorOrdenacaoProduto.DESIGNER_DECRESCENTE:
          listaProdutos = listaProdutos.sort((prod1, prod2) =>
            prod2.designer.name.localeCompare(prod1.designer.name),
          );
          break;
        case valorOrdenacaoProduto.NOME_DECRESCENTE:
          listaProdutos = listaProdutos.sort((prod1, prod2) =>
            prod2.name.localeCompare(prod1.name),
          );
          break;
        case valorOrdenacaoProduto.NOME_CRESCENTE:
          listaProdutos = listaProdutos.sort((prod1, prod2) =>
            prod1.name.localeCompare(prod2.name),
          );
          break;
        default:
      }
    }
    return listaProdutos;
  };

  const ordenacao = searchParams.get("ordenacao");

  const filtrarProdutos = () => {
    let produtosFiltrados = produtos;
    if (nome) {
      const nomeSemAcentos = retiraAcentosMinusculo(nome);
      produtosFiltrados = produtos.filter((produto) => {
        const nomeProdutoSemAcento = retiraAcentosMinusculo(produto.name);
        if (nomeProdutoSemAcento.includes(nomeSemAcentos)) {
          return produto;
        }
      });
    }
    if (categoria && usaCategoriaComoFiltro && categoria !== "todos") {
      produtosFiltrados = produtosFiltrados.filter(
        (produto) => produto.category === categoria,
      );
    }
    return ordenarProdutos(produtosFiltrados);
  };

  const { data: produtosApi } = useQuery({
    queryFn: () => filtrarProdutos(),
    queryKey: [nomeQuerysProduto.PRODUTOS_API, nome, categoria, ordenacao],
    enabled: !!produtos,
  });

  const {
    itensAtuais,
    paginaAtual,
    totalPaginas,
    proximaPagina,
    paginaAnterior,
    itensPorPagina,
    existePaginaAnterior,
    existeProximaPagina,
    irParaPagina,
    zerarPagina,
  } = usePaginacaoLista(produtosApi ?? []);

  const marcarCategoria = () => {
    let palavrasComCategoria = palavrasIniciais();
    if (categoria) {
      const categoriaTratada = retiraAcentosMinusculo(categoria);
      palavrasComCategoria = palavrasComCategoria.map((palavra) => {
        const valorTratado = retiraAcentosMinusculo(palavra.valor);
        const marcada = valorTratado == categoriaTratada;
        return { ...palavra, marcada: marcada };
      });
    }
    return palavrasComCategoria;
  };

  const { data: palavras } = useQuery({
    queryFn: () => marcarCategoria(),
    queryKey: [nomeQuerysProduto.CATEGORIAS_MARCADA, categoria],
  });

  const alterarNome = (nome) => {
    zerarPagina();
    setNome(nome);
  };

  const selecionarOrdenacao = (valor) => {
    const filtroCategoria = categoria ? { categoria } : {};
    if (valor) {
      setSearchParams({ ...filtroCategoria, ordenacao: valor });
      zerarPagina();
    } else {
      setSearchParams({ ...filtroCategoria });
    }
  };

  const selecionarCateoria = (valor) => {
    if (valor) {
      const filtroOrdenacao = ordenacao ? { ordenacao } : {};
      setSearchParams({ ...filtroOrdenacao, categoria: valor });
      zerarPagina();
    }
  };

  const [palavraMarcada] =
    palavras && palavras.length > 0
      ? palavras.filter((palavra) => palavra.marcada)
      : [{}];
  const categoriaSelecionada = palavraMarcada ? palavraMarcada.palavra : "";

  const navigate = useNavigate();

  const selecionarProduto = (produto) => {
    navigate(`/detalheProduto?produtoId=${produto._id}&origem=produtos`);
  };

  return {
    produtos: produtosApi ?? [],
    carregando,
    qtdeProdutos: produtosApi?.length ?? 0,
    itensAtuais,
    paginaAtual,
    totalPaginas,
    proximaPagina,
    paginaAnterior,
    itensPorPagina,
    existePaginaAnterior,
    existeProximaPagina,
    irParaPagina,
    nome,
    setNome: alterarNome,
    palavras: palavras ?? palavrasIniciais(),
    selecionarCateoria,
    camposOrdenacao: camposOrdenacaoProduto,
    ordenacao,
    selecionarOrdenacao,
    categoriaSelecionada,
    selecionarProduto,
  };
};
